const accessBtn = document.querySelector('.access');
const aboutArea = document.getElementById('aboutArea');

const scrollFunc = () => {
    const aboutPos = aboutArea.getBoundingClientRect().top;
    if (aboutPos + accessBtn.clientHeight < window.innerHeight) {
        if (!accessBtn.classList.contains('show')) {
            accessBtn.classList.add('show');
        }
    } else {
        if (accessBtn.classList.contains('show')) {
            accessBtn.classList.remove('show');
        }
    }
}

window.addEventListener('scroll', scrollFunc, false);
